import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "font-semibold text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_checkbox = _resolveComponent("base-checkbox")!
  const _component_avatar_img = _resolveComponent("avatar-img")!
  const _component_user_certificates_download = _resolveComponent("user-certificates-download")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_interview_modal = _resolveComponent("interview-modal")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!
  const _component_profile_detail_modal = _resolveComponent("profile-detail-modal")!
  const _component_base_button_primary = _resolveComponent("base-button-primary")!
  const _component_base_button_danger = _resolveComponent("base-button-danger")!
  const _component_teleport_to_content_footer = _resolveComponent("teleport-to-content-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "6",
      loading: _ctx.loading,
      onLoadMore: _ctx.loadMore,
      "has-more": _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      hover: ""
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "w-0 pr-0"
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "pR-3"
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "pl-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.fullname")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.email")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.certificates")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id,
            onClick: _withModifiers(($event: any) => (_ctx.showProfileDetail(row)), ["stop"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, { class: "w-0 pr-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_checkbox, {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                    value: row,
                    modelValue: _ctx.selectedCandidates,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCandidates) = $event))
                  }, null, 8, ["value", "modelValue"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "w-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_avatar_img, {
                    src: row.user.avatar_link,
                    alt: `${row.user.email}_avatar`
                  }, null, 8, ["src", "alt"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "pl-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.user.fullname), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.user.email), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.job.position.name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createVNode(_component_user_certificates_download, {
                    certificates: row.user.certificates
                  }, null, 8, ["certificates"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "onLoadMore", "has-more", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_interview_modal, {
      candidates: _ctx.selectedCandidates,
      onDone: _ctx.resetAction,
      modelValue: _ctx.showInterviewModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showInterviewModal) = $event))
    }, null, 8, ["candidates", "onDone", "modelValue"]),
    _createVNode(_component_confirm_modal, {
      type: "warning",
      modelValue: _ctx.confirmDeleteModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.confirmDeleteModal) = $event)),
      onYes: _ctx.rejects
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.trans("layout.modal_title.reject_applicants", {
          counts: _ctx.selectedCandidates.length,
        })), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "onYes"]),
    _createVNode(_component_profile_detail_modal, {
      modelValue: _ctx.showProfileDetailModal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showProfileDetailModal) = $event)),
      jobApplicant: _ctx.selectedRow,
      onInterviewCreated: _ctx.reload,
      onRejected: _ctx.reload
    }, null, 8, ["modelValue", "jobApplicant", "onInterviewCreated", "onRejected"]),
    _createVNode(_component_teleport_to_content_footer, {
      withCancelButton: false,
      withSaveButton: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_button_primary, {
          onClick: _ctx.addToInterview,
          disabled: !_ctx.selectedCandidates.length || _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.trans("layout.add_to_interview")) + " (" + _toDisplayString(_ctx.selectedCandidates.length) + ")", 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_base_button_danger, {
          disabled: !_ctx.selectedCandidates.length || _ctx.loading,
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showConfirmDelete(null)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.trans("layout.reject")) + " (" + _toDisplayString(_ctx.selectedCandidates.length) + ")", 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}