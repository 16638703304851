import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button_default = _resolveComponent("base-button-default")!
  const _component_base_button_primary = _resolveComponent("base-button-primary")!

  return (_ctx.contentFooterContainer)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#contentFooterContainer"
      }, [
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.withCancelButton)
          ? (_openBlock(), _createBlock(_component_base_button_default, {
              key: 0,
              to: { name: _ctx.currentRoute.meta?.rootName }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.button.cancel")), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        (_ctx.withSaveButton)
          ? (_openBlock(), _createBlock(_component_base_button_primary, {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pubSub.trigger(_ctx.EventNames.SaveButtonAppClick)))
            }, {
              default: _withCtx(() => [
                (_ctx.$slots.saveButton)
                  ? _renderSlot(_ctx.$slots, "saveButton", { key: 0 })
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.trans("layout.button.save")), 1))
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}