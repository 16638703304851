
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BaseButtonPrimary, BaseButtonDefault },
  mixins: [helperMixin],
  data() {
    return {
      contentFooterContainer: false
    };
  },
  async mounted() {
    await this.$nextTick();

    this.contentFooterContainer = document.getElementById(
      "contentFooterContainer"
    )
      ? true
      : false;
  },
  props: {
    withCancelButton: {
      type: Boolean,
      default: true
    },

    withSaveButton: {
      type: Boolean,
      default: true
    }
  }
});
